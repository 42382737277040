import "../navbar/navbar.css";
import "./homepage.css";
import grad from "../images/grad.png";
import logo from "../images/vitamaxxLogo.jpeg";
import food from "../images/food.png";

function Homepage() {
  return (
    <div>
      <div className="container2">
        <h1 className="desc2">
          Eating food provides a more balanced and complete range of nutrients
          than pills, and offers additional health benefits such as fiber and
          antioxidants.
        </h1>
        <img src={food} className="appLogo" />
      </div>
      <hr />
      <div className="container">
        <img src={logo} className="appLogo" />
        <h1 className="desc">
          Our App Will be Available on Both Apple and Android Devices
        </h1>
      </div>
      <hr />
    </div>
  );
}

export default Homepage;
