import Homepage from "./components/homepage/homepage";
import logo from "./components/images/vitamaxxLogo.jpeg";
import grad from "./components/images/grad.png";
import About from "./components/About/about";
import Contact from "./components/Contact/contact";
import Waitlist from "./components/Waitlist/waitlist";
import { useRef } from "react";

function App() {
  const about = useRef();
  const home = useRef();
  const contact = useRef();
  const waitlist = useRef();

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <header>
        <div class="topnav">
          <img src={logo} className="navbarLogo" />
          <a href="#waitlist" onClick={() => scrollToSection(waitlist)}>
            Waitlist
          </a>
          <a href="#contact" onClick={() => scrollToSection(contact)}>
            Contact
          </a>
          <a onClick={() => scrollToSection(about)} href="#about">
            About
          </a>
          <a class="active" href="#home" onClick={() => scrollToSection(home)}>
            Home
          </a>
          <br />
          <div className="closer">
            <p className="bigLogo">VITAMAXX</p>
            <h1 className="slogan">Transforming Your Vitamins</h1>
          </div>
        </div>
        <img src={grad} className="grad" />
        <div className="mobile">
          <br />
          <br />
          <p className="bigLogo">Vitamaxx</p>
          <h1 className="slogan">Transforming Your Vitamins</h1>
          <br />
          <hr></hr>
        </div>
        <div ref={home}>
          <Homepage />
        </div>
        <div ref={about}>
          <About />
        </div>
        <br />
        <br />
        <br />
        <div ref={contact}>
          <Contact />
        </div>
        <br />
        <br />
        <div ref={waitlist}>
          <Waitlist />
        </div>
      </header>
    </div>
  );
}

export default App;
