import React from "react";
import "./about.css";

function About() {
  return (
    <div>
      <div>
        <h1>About Vitamaxx</h1>
        <p>
          Vitamaxx is a revolutionary application that helps you optimize your
          nutrition and vitamin intake. With Vitamaxx, you can easily analyze
          your diet to determine what vitamins you're deficient in, and get
          recommendations on how to improve your nutrition. Vitamins are
          essential nutrients that your body needs to function properly.
          However, it can be difficult to get all the vitamins you need from
          your diet alone. That's where Vitamaxx comes in. By analyzing your
          diet, Vitamaxx can identify any gaps in your vitamin intake and
          provide you with personalized recommendations to help you optimize
          your nutrition.
        </p>
        <br />
        <br />
        <h1>How It Works</h1>
        <p>
          Using Vitamaxx is easy. Simply sign up and input your dietary
          information. Vitamaxx will analyze your diet to determine what
          vitamins you're deficient in, and provide you with personalized
          recommendations on how to improve your nutrition. Vitamaxx uses
          advanced algorithms and data analysis to provide you with the most
          accurate and up-to-date recommendations. Our team of nutrition experts
          and data scientists work tirelessly to ensure that our recommendations
          are based on the latest research and scientific findings.
        </p>
        <p>
          Vitamaxx uses advanced algorithms and data analysis to provide you
          with the most accurate and up-to-date recommendations. Our team of
          nutrition experts and data scientists work tirelessly to ensure that
          our recommendations are based on the latest research and scientific
          findings.
        </p>
        <br />
        <br />
        <h1>Our Mission</h1>
        <p>
          At Vitamaxx, our mission is to help people live healthier, happier
          lives by optimizing their nutrition and vitamin intake. We believe
          that everyone deserves access to personalized, data-driven nutrition
          recommendations, and we're committed to making that a reality. Thank
          you for choosing Vitamaxx. We're excited to help you achieve your
          health and wellness goals.
        </p>
      </div>
    </div>
  );
}

export default About;
